<template>
  <div class="login-form px-20 mdmax:px-0">
    <template v-if="!isSuccess">
      <div class="text-3xl font-bold mb-8">Reset Password</div>
      <div class="text-center text-sm mb-6">Please input your email to receive your new password.</div>
      <div class="mb-8">
        <TextField type="text" :borderEnabled="true" placeholder="Input your email address" label="Email Address" v-model="payloads.email" :error="errors['email']" />
      </div>
      <transition name="fade" mode="out-in">
        <div class="text-system-error text-center mb-6 mt-1 text-xs" v-if="generalError">{{ generalError }}</div>
      </transition>
      <div class="text-center mb-6">
        <Button buttonText="Reset Password" size="big" @action="doReset()" />
      </div>
      <div class="text-center text-sm"><span class="text-yellow cursor-pointer" @click="goToLogin()">Back to Login</span></div>
    </template>
    <template v-else>
      <div class="text-3xl font-bold mb-8">New Password Sent</div>
      <p class="text-center text-sm mb-8">
        We’ve sent a new password to <strong>{{ payloads.email }}</strong
        >. <br /><br />
        Please login with your new password to access Clouducation Live Streaming.
      </p>
      <div class="text-center">
        <Button buttonText="Okay" size="big" @action="close()" />
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { isNotEmptyError } from '@/utils'
const CONFIG = {
  email: 'Email'
}
export default {
  components: {
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  props: {
    coreClassId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    payloads: {
      email: ''
    },
    errors: [],
    isSuccess: false,
    generalError: ''
  }),
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('auth', ['resetPasswordEvent']),
    goToLogin() {
      this.$emit('close')
      this.$emit('login')
    },
    close() {
      this.$emit('close')
    },
    doReset() {
      if (this.validForm()) {
        // eslint-disable-next-line
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (!regex.test(this.payloads.email)) {
          this.errors['email'] = 'Email format is invalid!'
        } else {
          this.showLoading()
          this.resetPasswordEvent({
            payloads: {
              coreClassId: this.coreClassId,
              email: this.payloads.email
            }
          })
            .then((res) => {
              this.hideLoading()
              this.isSuccess = true
            })
            .catch(() => {
              this.hideLoading()
              this.generalError = 'The email address you have entered is not registered.'
            })
        }
      }
    },
    validForm() {
      const error = isNotEmptyError(this.payloads)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    }
  }
}
</script>
